/** @jsxImportSource @emotion/react */
import { FontWeight, RadioGroup, Text, Toggle } from '@ftbpro/mm-admin-ui-components';
import { Card } from '../../../Card/Card';
import { SearchCampaign, SearchProvider } from '../../../../../../createCampign.typs';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { targetState } from '../../targetState/target.atom';
import { useLocation } from 'react-router-dom';
import { TonicCampaign } from './components/TonicCampaign/TonicCampaign';
import { ExploreAdsCampaign } from './components/ExploreAdsCampaign/ExploreAdsCampaign';
import { AFSCampaign } from './components/AFSCampaign/AFSCampaign';
import { useEffect, useState } from 'react';
import { settingsState } from '../../../Settings/settingsState/settings.atom';
import { campaignsDraftTriggeredState } from '../../../../../../createCampaignState/createCampaign.selector';
import { searchState } from './searchCampaign.atom';
import { duplicateSettingsState } from '../../../Settings/components/DuplicateCampaignsSettings/duplicateSettings.state';
import { isDuplicateState } from '../../../DuplicationTarget/components/DuplicationType/DuplicationType.state';
import { css } from '@emotion/react';
import {HTTP_METHODS, mmNetworkService} from "../../../../../../../../../../../core/network/mmServicesApiProvider";

const PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL = process.env.REACT_APP_PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL;

const SEARCH_PROVIDER = [
  {
    label: 'Tonic',
    value: SearchProvider.Tonic,
  },
  {
    label: 'ExploreAds',
    value: SearchProvider.ExploreAds,
  },
  {
    label: 'AFS',
    value: SearchProvider.AFS,
  }
]

const styles = {
  toggleWrapper: css({
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
  })
}


interface SearchCampaignContainerProps {
  showToggle?: boolean;
}
export const SearchCampaignContainer = ({ showToggle }: SearchCampaignContainerProps) => {
  const [target, setTarget] = useRecoilState(targetState);
  const { triggered: applyDraftTriggered } = useRecoilValue(campaignsDraftTriggeredState);
  const setSettingsState = useSetRecoilState(settingsState);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isDuplicate = useRecoilValue(isDuplicateState);
  const { isSearch: duplicateIsSearch, searchProvider: duplicateSearchProvider } = useRecoilValue(duplicateSettingsState) || {};
  const isSearchCampaign = isDuplicate ? duplicateIsSearch as boolean : queryParams.get('isSearch') === 'True';
  const { searchCampaign } = target;
  const searchProvider = isDuplicate ? duplicateSearchProvider : searchCampaign?.searchProvider;
  const setIsSearchCampaign = useSetRecoilState(searchState);
  const [freeChannelsText, setFreeChannelsText] = useState('');
  const [showFreeChannelsText, setShowFreeChannelsText] = useState(false);

  const getFreeChannels = async (source: string) => {
    const pmCampaignsEndpoint = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/facebook/free-channels?source=${source}`;
    const configs = {
      method: HTTP_METHODS.GET,
    };

    const res = await mmNetworkService.fetch(pmCampaignsEndpoint, configs);

    console.log('getFreeChannels: res', res);
    return res;
  }

  useEffect(() => {
    setIsSearchCampaign({ isSearchCampaign })
    setTarget((prevTarget) => ({
      ...prevTarget,
      searchCampaign: {
        searchProvider
      } as SearchCampaign
    }));
  }, [isSearchCampaign, searchProvider]);

  useEffect(() => {
    if (showToggle) {
      setTarget((prevTarget) => ({
        ...prevTarget,
        useOriginSearchValues: true,
      }));
    }
  }, [showToggle]);

  const onSearchProviderChange = async (value: string) => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      searchCampaign: {
        searchProvider: value,
      } as SearchCampaign
    }));

    setSettingsState((prevSettings) => ({
      ...prevSettings,
      accountsDomains: [{ domain: '', accounts: [] }]
    }));

    await setFreeChannels(value);
  }

  const onToggleChange = () => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      useOriginSearchValues: !target.useOriginSearchValues,
      searchCampaign: {
        searchProvider: target.searchCampaign?.searchProvider
      } as SearchCampaign
    }));
  }

  const setFreeChannels = async (searchProvider: string) => {
    setShowFreeChannelsText(false);
    if (searchProvider === SearchProvider.Tonic) {
      return;
    }

    const source = searchProvider === SearchProvider.ExploreAds ? 'ExploreAds' : 'GoogleDirect';
    const freeChannels = await getFreeChannels(source);
    console.log('freeChannels', freeChannels);

    setFreeChannelsText(`There are ${freeChannels} free channels left for ${source}`);
    setShowFreeChannelsText(true);
  }

  return (
    isSearchCampaign ?
      <Card>
        <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium} >Search Campaign</Text>
        <RadioGroup
          items={SEARCH_PROVIDER}
          orientation={RadioGroup.ORIENTATION.HORIZONTAL}
          onCheckChanged={onSearchProviderChange}
          checkedValue={searchProvider}
          disabled={applyDraftTriggered || isDuplicate}
        />
        <Text type={Text.TEXT_TYPES.PARAGRAPH_M} style={{display: showFreeChannelsText ? 'block' : 'none' }}>{freeChannelsText}</Text>
        {
          showToggle &&
          <div css={styles.toggleWrapper}>
            <Toggle
              onChange={onToggleChange}
              checked={target.useOriginSearchValues}
            />
            <Text
              type={Text.TEXT_TYPES.PARAGRAPH_M}
            >
              Use Origin Search Values
            </Text>
          </div>
        }
        {
          !target.useOriginSearchValues &&
          <>
            {
              searchProvider === SearchProvider.Tonic &&
              <TonicCampaign />
            }

            {
              searchProvider === SearchProvider.ExploreAds &&
              <ExploreAdsCampaign />
            }

            {
              searchProvider === SearchProvider.AFS &&
              <AFSCampaign />
            }
          </>
        }
      </Card>
      : null
  );
}
